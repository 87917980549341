$(function () {
    // お気に入りボタンクリック時
    $(document).on('click', '.favorite-heart', function (event) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        
        // flgがundefined＝ログイン済み
        let flg = $(this).data('dialog-target');
        if (flg !== undefined) {
            // 未ログインの場合中止
            return;
        }
        
        np_jp.lock();
        
        let item_unique_key = $(this).data('item-unique-key');
        $form = $('#form' + item_unique_key);
        $.ajax({
            url: $form.attr('action'),
            type: $form.attr('method'),
            data: $form.serializeArray(),
            dataType: 'json',
        }).done(function (data) {
            np_jp.unlock();
            $('.' + data['item_unique_key']).toggleClass("favorite-heart-check");
            $('#form' + data['item_unique_key']).find('[name="fav_flg"]').val(data['favorite_flg']);

        }).fail(function (data) {
        });
    });


    $(document).on('click', '.card', function () {
        let item_prefix = $(this).data('dialog-prefix');
        let item_code = $(this).data('dialog-code');
        let item_branch = $(this).data('dialog-branch');
    
        window.location.href = location.origin
                + '/item/detail?item_prefix=' + item_prefix
                + '&item_code=' + item_code
                + '&item_branch=' + item_branch;
    });
});